import { Directive, inject, OnDestroy } from '@angular/core';
import { BehaviorSubject, concatMap, map, Observable, of, Subject, Subscription, switchMap, tap } from 'rxjs';
import { LoggerService } from '@dougs/core/logger';
import { mergeObjects, RecursivePartial } from '@dougs/core/utils';

@Directive()
export abstract class AbstractQueueService<Model, QueueParams> implements OnDestroy {
  private readonly logger: LoggerService = inject(LoggerService);

  protected constructor() {
    this.updateModelSubscription = this.callInQueue$.subscribe();
  }

  private readonly updateModelSubscription!: Subscription;
  protected stopQueueSubject: BehaviorSubject<string> = new BehaviorSubject('');
  protected callSubject: Subject<[() => Observable<Model | null>, QueueParams]> = new Subject<
    [() => Observable<Model | null>, QueueParams]
  >();
  protected call$: Observable<[() => Observable<Model | null>, QueueParams]> = this.callSubject.asObservable();
  protected queue: string[] = [];
  private _model!: Model;

  protected abstract updateModelInState: (model: Model, queueParams: QueueParams) => void;
  protected abstract resetCall: (queueParams: QueueParams) => Observable<Model | null>;
  protected abstract buildCall: (modelUpdated: Model, queueParams: QueueParams) => () => Observable<Model | null>;
  protected preCallTreatmentFn: (queueParams: QueueParams) => void = () => void 0;
  protected postCallTreatmentFn: (callResult: Model | null, queueParams: QueueParams) => void = () => void 0;

  public get model(): Model {
    return this._model;
  }

  public setCurrentModel(model: Model): void {
    this._model = model;
  }

  protected getUpdatedModel(updatedModel: RecursivePartial<Model>): Model {
    return mergeObjects(this.model, updatedModel);
  }

  public callInQueue$: Observable<Model | null> = this.stopQueueSubject.asObservable().pipe(
    switchMap(() =>
      this.call$.pipe(
        tap(([_, queueParams]: [() => Observable<Model | null>, QueueParams]) => this.preCallTreatmentFn(queueParams)),
        concatMap(
          ([call, queueParams]: [() => Observable<Model | null>, QueueParams]): Observable<
            [Model | null, QueueParams]
          > => call().pipe(map((result: Model | null) => [result, queueParams])),
        ),
        map(([result, queueParams]: [Model | null, QueueParams]) => {
          this.postCallTreatmentFn(result, queueParams);
          return [result, queueParams] as [Model | null, QueueParams];
        }),
      ),
    ),
    concatMap(([value, queueParams]: [Model | null, QueueParams]) => {
      if (value) {
        this.queue.shift();
        if (!this.queue?.length) {
          this.updateModelInState(value, queueParams);
        }
        return of(value);
      }
      this.resetQueue();
      return this.resetCall(queueParams);
    }),
  );

  public updateModel(partialModel: RecursivePartial<Model>, queueParams: QueueParams): void {
    if (!this.model) {
      this.logger.error('You need to set a model to update it !');
      return;
    }
    const updatedModel: Model = this.getUpdatedModel(partialModel);
    this.setCurrentModel(updatedModel);
    const call: () => Observable<Model | null> = this.buildCall(updatedModel, queueParams);
    this.addToQueue(call, queueParams);
  }

  public addToQueue(call: () => Observable<Model | null>, queueParams: QueueParams): void {
    this.queue.push('');
    this.callSubject.next([call, queueParams]);
  }

  public resetQueue(): void {
    this.queue = [];
    this.stopQueueSubject.next('');
  }

  ngOnDestroy(): void {
    this.updateModelSubscription?.unsubscribe();
  }
}
